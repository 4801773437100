import React from 'react';
import Favicon from './Favicon';
import ApplicationWrp from '@/Framework/UI/Templates/ApplicationWrp';
import ContentWrp from '@/Framework/UI/Templates/ContentWrp';

interface IProps {
  children: React.ReactNode,
}

const Layout = (props: IProps) => (
  <>
    <Favicon />
    <ApplicationWrp>
      <ContentWrp>
        { props.children }
      </ContentWrp>
    </ApplicationWrp>
  </>
);

export default Layout;
