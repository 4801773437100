import Layout from './Layout';
import LandingLayout from './LandingLayout';
import StaticPageLayout from './StaticPageLayout';

export {
  LandingLayout,
  StaticPageLayout,
};

export default Layout;
